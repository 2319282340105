import { z } from "zod";

import { targetChainSchema } from "../types";

export const createTargetChainEndpointRequestSchema = targetChainSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export type ICreateTargetChainEndpointRequest = z.input<
  typeof createTargetChainEndpointRequestSchema
>;

export const updateTargetChainEndpointRequestSchema = targetChainSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
  })
  .strict();

export type IUpdateTargetChainEndpointRequest = z.input<
  typeof updateTargetChainEndpointRequestSchema
>;
