import { initContract } from "@ts-rest/core";

import { aiContract } from "./ai";
import { calendarContract } from "./calendar";
import { indirectsContract } from "./indirects";
import { programContract } from "./programs";
export * from "./calendar";
export * from "./programs";

const c = initContract();

export const contract = c.router({
  programs: programContract,
  ai: aiContract,
  calendar: calendarContract,
  indirects: indirectsContract,
});
