import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IndirectReason, indirectSchema } from "../types";

const c = initContract();
export const indirectsContract = c.router({
  getById: {
    method: "GET",
    path: "/clinics/:clinicId/indirects/:id",
    pathParams: z.object({
      clinicId: z.string(),
      id: z.string(),
    }),
    responses: {
      200: z.object({ indirect: indirectSchema }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getForWeekAndClinicId: {
    method: "GET",
    path: "/indirects/get-for-week-and-clinic-id",
    query: z.object({
      week: z.coerce.number(),
      year: z.coerce.number(),
      clinicId: z.string(),
      timeZone: z.string(),
    }),
    responses: {
      200: z.array(indirectSchema),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getByClinicOrUser: {
    method: "GET",
    path: "/indirects",
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      clinicId: z.string(),
      userEmail: z.string().optional(),
    }),
    responses: {
      200: z.array(indirectSchema),
    },
  },
  deleteIndirect: {
    method: "DELETE",
    path: "/clinics/:clinicId/indirects/:id",
    pathParams: z.object({
      clinicId: z.string(),
      id: z.string(),
    }),
    body: z.object({
      deleteRecurringEvents: z.boolean(),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  updateIndirect: {
    method: "PUT",
    path: "/clinics/:clinicId/indirects/:id",
    pathParams: z.object({
      clinicId: z.string(),
      id: z.string(),
    }),
    body: z.object({
      data: z.object({
        indirectReason: z.nativeEnum(IndirectReason),
        summary: z.string(),
        description: z.string(),
        startMs: z.number(),
        endMs: z.number(),
        isDirectPayRate: z.boolean(),
        rrule: z.string().nullish(),
      }),
      updateRecurrences: z.boolean(),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  createIndirect: {
    method: "POST",
    path: "/clinics/:clinicId/indirects",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    body: z.object({
      attendeeEmails: z.array(z.string()),
      summary: z.string(),
      description: z.string(),
      startMs: z.number(),
      endMs: z.number(),
      timeZone: z.string(),
      indirectReason: z.nativeEnum(IndirectReason),
      isDirectPayRate: z.boolean(),
      rrule: z.string().nullish(),
    }),
    responses: {
      200: z.object({ id: z.string() }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
