import { DROPBOX_HANDBOOK_TEMPLATE_IDS } from "./consts";

export type DropboxHandbookTemplateId =
  (typeof DROPBOX_HANDBOOK_TEMPLATE_IDS)[keyof typeof DROPBOX_HANDBOOK_TEMPLATE_IDS];

export interface IDropboxContract {
  [key: string]: {
    title: string;
    subject: string;
    message: string;
    templateId: string;
    signerRole: DropboxDocumentSignerRole;
    previewURL?: string;
    customFields: {
      [key: string]: string;
    };
  };
}

export enum DropboxDocumentType {
  CAREGIVER_CONSENT_FORM = "Caregiver Consent Form",
  MEDIA_CONSENT_FORM = "Media Consent Form",
  THIRD_PARTY_CONSENT_FORM = "Third Party Consent Form",
  SCHOOL_OR_AGENT_THIRD_PARTY_CONSENT_FORM = "Third Party Consent Form (School or Agent)",
  CAREGIVER_HANDBOOK = "Client / Caregiver Handbook",
  CLIENT_BILLING_ACKNOWLEDGEMENT_FORM = "Client Billing Acknowledgement Form",
}

export interface ISignedDropboxDocument {
  documentType: DropboxDocumentType;
  signedAt: number;
  signatureRequestId: string;
  signerEmail: string;
  signerName: string;
  signerRole: string;
}

export enum DropboxDocumentSignerRole {
  "Parent/Guardian" = "Parent / Guardian",
}
