export enum FirestoreCollectionName {
  APPOINTMENTS = "appointments",
  AUTHORIZATIONS = "authorizations",
  BILLED_APPOINTMENTS = "billed-appointments",
  CLIENT_AVAILABILITY = "client-availability",
  CLIENT_FILES = "client-files",
  CLIENTS = "clients",
  CLINICS = "clinics",
  COMPLETED_APPOINTMENTS = "completed-appointments",
  APPOINTMENT_AUDIT_RESULTS = "appointment-audit-results",
  DEPLOY_METADATA = "deploy-metadata",
  EVENT_LINES = "event-lines",
  GUARDIANS = "guardians",
  INQUIRES = "inquiries",
  INDIRECTS = "indirects",
  INVITES = "invites",
  LIBRARIES = "libraries",
  LIBRARY_TAGS = "library-tags",
  MEASUREMENTS = "measurements",
  NOTES = "notes",
  PAYER_VERIFICATION = "payer-verification",
  PAYERS = "payers",
  PROGRAMS = "programs",
  PROGRAM_TEMPLATES = "program-templates",
  SESSIONS = "sessions",
  STATUS_TRANSITIONS = "status-transitions",
  TARGETS = "targets",
  TRANSITION_CRITERIA = "transition-criteria",
  TARGET_CHAINS = "target-chains",
  UPDATE_WORKING_HOURS = "update-working-hours",
  USER_AVAILABILITY = "user-availability",
  USER_PAY_RATES = "user-pay-rates",
  USERS = "users",
  CLIENT_PINS = "client-pins",
}
