import moment, { Moment } from "moment";

export const getDatesBetween = <TOutput = moment.Moment>({
  startDate,
  endDate,
  interval,
  formatter = (date) => date as TOutput,
}: {
  startDate: Moment;
  endDate: Moment;
  interval: "week" | "month" | "day";
  formatter?: (date: Moment) => TOutput;
}): TOutput[] => {
  const dates: TOutput[] = [];
  let currentDate = moment(startDate);

  while (currentDate <= endDate) {
    dates.push(formatter(currentDate));
    currentDate = moment(currentDate).add(1, interval);
  }

  return dates;
};

export function combineDateAndTime({
  dateMoment,
  timeMoment,
}: {
  dateMoment: Moment;
  timeMoment: Moment;
}) {
  return moment(timeMoment).set({
    year: dateMoment.year(),
    month: dateMoment.month(),
    date: dateMoment.date(),
  });
}

export const getWeekOfMonth = (moment: Moment) => {
  return 1 + Math.floor((moment.date() - 1) / 7);
};
