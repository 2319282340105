import { FieldValue, Timestamp } from "firebase/firestore";
import { z } from "zod";

import { AppointmentLocation } from "./calendar";
import { BillingCode, Modifier, signatureSchema } from "./general";

// ENUMS

export enum NoteTypes {
  RBT_SUPERVISION_FEEDBACK = "RBT Supervision Feedback (Indirect supervision) Form",
  PARENT_SESSION = "Parent Session Note",
  GROUP_PARENT_SESSION = "Group Parent Session Note",
  BCBA_CLINICAL_MANAGEMENT = "BCBA CM",
  BCBA_ASSESSMENT = "BCBA Assessment",
  BCBA_DIRECT_SUPERVISION = "BCBA Direct Supervision Session Note",
  THERAPIST_SESSION = "Therapist Session Note",
  TRICARE_THERAPIST_SESSION = "Tricare - Therapist Session Note",
  COLORADO_MEDICAID_THERAPIST_SESSION = "Colorado Medicaid - Therapist Session Note",
  BCBA_SOCIAL_GROUP = "BCBA Social Group",
  ADDITIONAL_ASSESSMENT = "Additional Assessment",
}

// Only used for UI. Not stored in Firestore.
export enum NoteStatus {
  DRAFT = "Draft",
  PENDING_SIGNATURE = "Pending signature",
  PENDING_APPROVAL = "Pending approval",
  APPROVED = "Approved",
}

export enum NOTE_FIELD_TYPES {
  TEXT = "text",
  BOOLEAN = "boolean",
  DROPDOWN = "dropdown",
  BLOB = "blob",
  OPTIONAL = "optional",
}

export enum NOTE_FIELDS {
  PATIENT_NAME = "patientName",
  ALIAS = "alias",
  DOB = "DOB",
  PROVIDER_NAME = "providerName",
  PROVIDER_NPI = "providerNPI",
  DATE = "date",
  START_TIME = "startTime",
  END_TIME = "endTime",
  APPOINTMENT_TYPE = "appointmentType",
  LOCATION = "location",
  NARRATIVE = "narrative",
  PROVIDER_SIGNED = "providerSigned",
  CLIENT_SIGNED = "clientSigned",
  TARGET_DATA = "targetData",
  BEHAVIOR_DATA = "behaviorData",
}

export const NOTE_FIELD_LABELS = {
  [NOTE_FIELDS.PATIENT_NAME]: "Patient Name",
  [NOTE_FIELDS.ALIAS]: "Alias",
  [NOTE_FIELDS.DOB]: "Date of Birth",
  [NOTE_FIELDS.PROVIDER_NAME]: "Provider Name",
  [NOTE_FIELDS.PROVIDER_NPI]: "Provider NPI",
  [NOTE_FIELDS.DATE]: "Date",
  [NOTE_FIELDS.START_TIME]: "Start Time",
  [NOTE_FIELDS.END_TIME]: "End Time",
  [NOTE_FIELDS.APPOINTMENT_TYPE]: "Appointment Type",
  [NOTE_FIELDS.LOCATION]: "Location",
  [NOTE_FIELDS.NARRATIVE]: "Narrative",
  [NOTE_FIELDS.PROVIDER_SIGNED]: "Provider Signature",
  [NOTE_FIELDS.CLIENT_SIGNED]: "Client Signature",
  [NOTE_FIELDS.TARGET_DATA]: "Target Data",
  [NOTE_FIELDS.BEHAVIOR_DATA]: "Behavior Data",
};

// TYPES

export const motivityNoteDataSchema = z.object({
  clientFullName: z.string(),
  providerFullName: z.string(),
  dateOfBirth: z.string(),
  targetData: z.string(),
  behaviorData: z.string(),
  billingCode: z.nativeEnum(BillingCode),
  modifiers: z.array(z.nativeEnum(Modifier)).or(z.string()),
  manualOverride: z.boolean(),
  errors: z.array(z.string()),
});

export const orbitNoteDataBcbaAssessmentSchema = z.object({
  peoplePresent: z.array(z.string()),
  assessmentsUsed: z.array(z.string()),
  methodsUsed: z.array(z.string()),
  plan: z.array(z.string()),
});

export const orbitNoteDataBcbaClinicalManagementSchema = z.object({
  plan: z.array(z.string()),
});

export const orbitNoteDataBcbaDirectSupervisionSchema = z.object({
  peoplePresent: z.array(z.string()),
  clientSubjectiveState: z.array(z.string()),
  environmentalFactors: z.array(z.string()),
  clinicalStatus: z.array(z.string()),
  trendForChallengingBehaviors: z.string(),
  modifications: z.string(),
  plan: z.array(z.string()),
});

export const orbitNoteDataParentSessionSchema = z.object({
  peoplePresent: z.array(z.string()),
  environmentalFactors: z.array(z.string()),
  plan: z.array(z.string()),
});

export const orbitNoteDataRbtSupervisionFeedbackSchema = z.object({
  changesToProgramming: z.string(),
});

export const orbitNoteDataTherapistSessionSchema = z.object({
  peoplePresent: z.array(z.string()),
  patientBehavior: z.array(z.string()),
  environmentalFactors: z.array(z.string()),
  clinicalStatus: z.array(z.string()),
  plan: z.array(z.string()),
});

export const orbitNoteDataTricareTherapistSessionSchema = z.object({
  authorizedAbaSupervisor: z.string(),
  guardianTraining: z.string(),
  exactLocation: z.string(),
  observableAndMeasurable: z.string(),
  peoplePresent: z.array(z.string()),
  ableToParticipate: z.array(z.string()),
  environmentalFactors: z.array(z.string()),
  clinicalStatus: z.array(z.string()),
  antecedents: z.array(z.string()),
  consequences: z.array(z.string()),
  barriers: z.array(z.string()),
  progressTowardsGoals: z.array(
    z.object({
      progress: z.string().nullable(),
      goal: z.string().nullable(),
    })
  ),
  additionalProgress: z.string(),
  sessionContentDescription: z.string(),
  plan: z.array(z.string()),
});

export const orbitNoteDataColoradoMedicaidTherapistSessionSchema = z.object({
  plan: z.array(z.string()),
  motivityNoteLink: z.string(),
});

export const orbitNoteDataBcbaSocialGroupSchema = z.object({
  authorizedAbaSupervisor: z.string(),
  peoplePresent: z.array(z.string()),
  environmentalFactors: z.array(z.string()),
  clientSubjectiveState: z.array(z.string()),
  clinicalStatus: z.array(z.string()),
  additionalProgress: z.string(),
  sessionContentDescription: z.string(),
  plan: z.array(z.string()),
});

// From Zod docs:
// "Zod will test the input against each of the "options" *in order* and return the first value that validates successfully."
// So technically the way we are validating right now doesn't guarantee that the noteData is valid for the noteType.
// Because Zod will just check to see if it's valid for any schema in the union in order.
// TODO(Chloe): Redo note request schema validations to have some custom logic for checking the data field of noteSchema
// depending on the noteType.
export const noteDataSchema = z.union([
  motivityNoteDataSchema,
  orbitNoteDataBcbaDirectSupervisionSchema,
  orbitNoteDataTherapistSessionSchema,
  orbitNoteDataTricareTherapistSessionSchema,
  orbitNoteDataBcbaAssessmentSchema,
  orbitNoteDataParentSessionSchema,
  orbitNoteDataRbtSupervisionFeedbackSchema,
  orbitNoteDataBcbaClinicalManagementSchema,
  orbitNoteDataColoradoMedicaidTherapistSessionSchema,
  orbitNoteDataBcbaSocialGroupSchema,
]);

/**
 * A clinical note for appointments. Includes necessary details to approve/bill the appointment.
 *
 * @param id The note's ID
 * @param isOrbitNote Used to define if it was created in Motivity or Orbit
 * @param appointmentId The ID of the {@link IAppointment} that the note belongs to
 * @param clientId The ID of the {@link IClient} that the note belongs to
 * @param clinicId The ID of the cli{@link IClinic} that the note belongs to
 * @param userId The ID of the {@link IUser} that the note belongs to
 * @param motivityUUID The Motivity UUID of the note
 * @param startMs The start time of the note in milliseconds. Only used for Motivity notes. The source of truth for completed appointments.
 * @param endMs The end time of the note in milliseconds. Only used for Motivity notes. The source of truth for completed appointments.
 * @param location The location of the note. Only used for Motivity notes. The source of truth for completed appointments.
 * @param guardianSignature The {@link ISignature} for the guardian
 * @param providerSignature The {@link ISignature} for the provider
 * @param approved Whether the note has been approved
 * @param narrative The session narrative
 * @param noteType The note's {@link NoteTypes}
 * @param data The extra fields on the note {@link noteDataSchema}
 */
export const noteSchema = z.object({
  id: z.string(),
  isOrbitNote: z.boolean(),
  appointmentId: z.string().nullable(),
  clientId: z.string(),
  clinicId: z.string(),
  userId: z.string(),
  // TODO: Remove fields that are only used for Motivity notes and put them in motivityNoteDataSchema.
  motivityUUID: z.string().nullable().optional(), // Only used for Motivity notes
  startMs: z.number(), // Only used for Motivity notes
  endMs: z.number(), // Only used for Motivity notes
  location: z.nativeEnum(AppointmentLocation), // Only used for Motivity notes
  guardianSignature: signatureSchema.nullable(),
  providerSignature: signatureSchema.nullable(),
  approved: z.boolean(),
  narrative: z.string(),
  noteType: z.nativeEnum(NoteTypes),
  data: noteDataSchema,
  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullable(),
});

export const motivityNoteSchema = noteSchema.merge(
  z.object({
    data: motivityNoteDataSchema,
  })
);

export const orbitNoteBcbaAssessmentSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataBcbaAssessmentSchema,
  })
);

export const orbitNoteBcbaClinicalManagementSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataBcbaClinicalManagementSchema,
  })
);

export const orbitNoteBcbaDirectSupervisionSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataBcbaDirectSupervisionSchema,
  })
);

export const orbitNoteParentSessionSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataParentSessionSchema,
  })
);

export const orbitNoteRbtSupervisionFeedbackSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataRbtSupervisionFeedbackSchema,
  })
);

export const orbitNoteTherapistSessionSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataTherapistSessionSchema,
  })
);

export const orbitNoteTricareTherapistSessionSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataTricareTherapistSessionSchema,
  })
);

export const orbitNoteColoradoMedicaidTherapistSessionSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataColoradoMedicaidTherapistSessionSchema,
  })
);

export const orbitNoteBcbaSocialGroupSchema = noteSchema.merge(
  z.object({
    data: orbitNoteDataBcbaSocialGroupSchema,
  })
);

export type IMotivityNoteData = z.input<typeof motivityNoteDataSchema>;
export type IOrbitNoteDataBcbaAssessment = z.input<typeof orbitNoteDataBcbaAssessmentSchema>;
export type IOrbitNoteDataBcbaClinicalManagement = z.input<
  typeof orbitNoteDataBcbaClinicalManagementSchema
>;
export type IOrbitNoteDataBcbaDirectSupervision = z.input<
  typeof orbitNoteDataBcbaDirectSupervisionSchema
>;
export type IOrbitNoteDataParentSession = z.input<typeof orbitNoteDataParentSessionSchema>;
export type IOrbitNoteDataRbtSupervisionFeedback = z.input<
  typeof orbitNoteDataRbtSupervisionFeedbackSchema
>;
export type IOrbitNoteDataTherapistSession = z.input<typeof orbitNoteDataTherapistSessionSchema>;
export type IOrbitNoteDataTricareTherapistSession = z.input<
  typeof orbitNoteDataTricareTherapistSessionSchema
>;
export type IOrbitNoteDataColoradoMedicaidTherapistSession = z.input<
  typeof orbitNoteDataColoradoMedicaidTherapistSessionSchema
>;
export type IOrbitNoteDataBcbaSocialGroup = z.input<typeof orbitNoteDataBcbaSocialGroupSchema>;

export type IMotivityNote = z.input<typeof motivityNoteSchema>;
export type IOrbitNoteBcbaAssessment = z.input<typeof orbitNoteBcbaAssessmentSchema>;
export type IOrbitNoteBcbaClinicalManagement = z.input<
  typeof orbitNoteBcbaClinicalManagementSchema
>;
export type IOrbitNoteBcbaDirectSupervision = z.input<typeof orbitNoteBcbaDirectSupervisionSchema>;
export type IOrbitNoteParentSession = z.input<typeof orbitNoteParentSessionSchema>;
export type IOrbitNoteRbtSupervisionFeedback = z.input<
  typeof orbitNoteRbtSupervisionFeedbackSchema
>;
export type IOrbitNoteTherapistSession = z.input<typeof orbitNoteTherapistSessionSchema>;
export type IOrbitNoteTricareTherapistSession = z.input<
  typeof orbitNoteTricareTherapistSessionSchema
>;
export type IOrbitNoteBcbaSocialGroup = z.input<typeof orbitNoteBcbaSocialGroupSchema>;
export type IOrbitNoteColoradoMedicaidTherapistSession = z.input<
  typeof orbitNoteColoradoMedicaidTherapistSessionSchema
>;

export type INoteData = z.input<typeof noteDataSchema>;

export type INote = z.input<typeof noteSchema>;

export interface ILegacyNote {
  id: string;
  motivityUUID?: string;
  isOrbitNote: false;

  //leave empty if not matched to an appointment to query
  appointmentId: string;
  clientId: string;
  userId: string;
  clinicId: string;

  noteType: NoteTypes;

  clientFullName: string;
  providerFullName: string;
  dateOfBirth: string;
  narrative: string;
  location: AppointmentLocation;
  targetData: string;
  behaviorData: string;

  //optional to accomodate for unbillable notes, leave empty if unbillable
  billingCode: BillingCode | string;
  modifiers: Modifier[] | string;

  startMs: number;
  endMs: number;

  //will be NaN if not signed/parsed
  providerSignedMs: number;
  clientSignedMs: number;

  approved: boolean;
  manualOverride: boolean; //default to false
  errors: string[];

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}
