import { FieldValue, Timestamp } from "firebase/firestore";
import { Moment } from "moment";
import { z } from "zod";

import {
  AppName,
  BillingCode,
  IAuthorization,
  IClient,
  IClientDetails,
  IClientFile,
  IClinic,
  IExpectedRevenue,
  IGuardian,
  IPayer,
  IUser,
  Modifier,
  Weekday,
} from "./general";
import { INote } from "./notes";

// ENUMS

/**
 * Different types of appointments that exist within Finni
 * APPOINTMENT: An appointment that either lives in Firestore or GCal
 * COMPLETED: An appointment that has been completed and is ready to be billed, lives in Firestore only
 * BILLED: An appointment that has been billed, lives in Firestore only
 * INDIRECT: An indirect appointment, lives in Firestore only
 */
export enum EventType {
  APPOINTMENT = "APPOINTMENT",
  COMPLETED = "COMPLETED",
  BILLED = "BILLED",
  INDIRECT = "INDIRECT",
}

/**
 * Display name for different appointment types.
 */
export enum AppointmentType {
  INITIAL_ASSESSMENT = "Initial Assessment",
  INITIAL_ASSESSMENT_IN_CLINIC = "Initial Assessment (In Clinic)",
  INITIAL_ASSESSMENT_OUT_OF_CLINIC = "Initial Assessment (Out of Clinic)",
  INITIAL_ASSESSMENT_TELEHEALTH = "Initial Assessment (Telehealth)",
  UPDATED_ASSESSMENT = "Updated Assessment",
  ADDITIONAL_ASSESSMENT = "Additional Assessment",
  ADDITIONAL_ASSESSMENT_IN_CLINIC = "Additional Assessment (In Clinic)",
  ADDITIONAL_ASSESSMENT_OUT_OF_CLINIC = "Additional Assessment (Out of Clinic)",
  ADDITIONAL_ASSESSMENT_TELEHEALTH = "Additional Assessment (Telehealth)",
  ASSESSMENT_OUTCOME_MEASURES = "Assessment - Outcome Measures",
  ADAPTIVE_BEHAVIOR_TREATMENT = "Adaptive Behavior Treatment",
  ADAPTIVE_BEHAVIOR_TREATMENT_IN_CLINIC = "Adaptive Behavior Treatment (In Clinic)",
  ADAPTIVE_BEHAVIOR_TREATMENT_OUT_OF_CLINIC = "Adaptive Behavior Treatment (Out of Clinic)",
  ADAPTIVE_BEHAVIOR_TREATMENT_TELEHEALTH = "Adaptive Behavior Treatment (Telehealth)",
  DIRECT_SERVICES = "Direct Services",
  DIRECT_SERVICES_IN_CLINIC = "Direct Services (In Clinic)",
  DIRECT_SERVICES_OUT_OF_CLINIC = "Direct Services (Out of Clinic)",
  DIRECT_SERVICES_TELEHEALTH = "Direct Services (Telehealth)",
  DIRECT_SUPERVISION = "Direct Supervision",
  DIRECT_SUPERVISION_IN_CLINIC = "Direct Supervision (In Clinic)",
  DIRECT_SUPERVISION_OUT_OF_CLINIC = "Direct Supervision (Out of Clinic)",
  DIRECT_SUPERVISION_TELEHEALTH = "Direct Supervision (Telehealth)",
  PARENT_TRAINING = "Parent Training",
  PARENT_TRAINING_IN_CLINIC = "Parent Training (In Clinic)",
  PARENT_TRAINING_OUT_OF_CLINIC = "Parent Training (Out of Clinic)",
  PARENT_TRAINING_TELEHEALTH = "Parent Training (Telehealth)",
  GROUP_PARENT_TRAINING = "Group Parent Training",
  DIRECT_OR_INDIRECT_SUPERVISION = "Direct/Indirect Supervision",
  CLINICAL_MANAGEMENT = "Clinical Management",
  GROUP_ADAPTIVE_BEHAVIOR = "Group Adaptive Behavior",
  GROUP_ADAPTIVE_BEHAVIOR_IN_CLINIC = "Group Adaptive Behavior (In Clinic)",
  GROUP_ADAPTIVE_BEHAVIOR_OUT_OF_CLINIC = "Group Adaptive Behavior (Out of Clinic)",
  GROUP_ADAPTIVE_BEHAVIOR_TELEHEALTH = "Group Adaptive Behavior (Telehealth)",
  GROUP_ADAPTIVE_BEHAVIOR_TWO_PATIENTS = "Group Adaptive Behavior (2 Patients)",
  GROUP_ADAPTIVE_BEHAVIOR_THREE_PATIENTS = "Group Adaptive Behavior (3 Patients)",
  GROUP_ADAPTIVE_BEHAVIOR_FOUR_PATIENTS = "Group Adaptive Behavior (4 Patients)",
  GROUP_ADAPTIVE_BEHAVIOR_FIVE_PATIENTS = "Group Adaptive Behavior (5 Patients)",
  GROUP_ADAPTIVE_BEHAVIOR_SIX_OR_MORE_PATIENTS = "Group Adaptive Behavior (6+ Patients)",
}

/**
 * Approval status of an indirect.
 */
export enum IndirectStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * Approval status of an appointment cancelation request.
 */
export enum CancelledCompletedAppointmentStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * Reason for an indirect rejection.
 */
export enum IndirectRejectionReason {
  OVERUSE = "OVERUSE",
  INVALID_USE_CASE = "INVALID_USE_CASE",
  OTHER = "OTHER",
}

/**
 * Different types of locations that an appointment can take place.
 * Enum values use location codes as regulated by insurance companies.
 */
export enum AppointmentLocation {
  HOME = "12",
  OFFICE = "11",
  TELEHEALTH = "2",
  SCHOOL = "3",
  OTHER = "99",
}

/**
 * The source of an appointment. Either from Google Calendar or from Firestore.
 */
export enum AppointmentSource {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  FIRESTORE = "FIRESTORE",
}

/**
 * Attending status of an attendee of an appointment.
 * Values from GCAL API
 */
export enum AttendeeStatus {
  NEEDS_ACTION = "needsAction",
  ACCEPTED = "accepted",
  DECLINED = "declined",
  TENTATIVE = "tentative",
}

/**
 * Calendar view types in Mission Control
 */
export enum CalendarType {
  CLIENTS = "clients",
  THERAPISTS = "therapists",
  LIST = "list",
}

/**
 * Extends EventType to include additional types of events
 */
export enum CalendarEventType {
  //extends EventType, TS doesn't allow enum extensions
  APPOINTMENT = EventType.APPOINTMENT,
  COMPLETED = EventType.COMPLETED,
  INDIRECT = EventType.INDIRECT,
  APPOINTMENT_HIDDEN = "APPOINTMENT_HIDDEN",
  CANCELLED = "CANCELLED",
}

/**
 * Event recurrence options
 */
export enum CalendarRecurrenceOption {
  NO_REPEAT = "Doesn't repeat",
  DAILY = "Daily",
  WEEKLY = "Weekly on",
  BIWEEKLY = "Bi-Weekly on",
  MONTHLY = "Monthly on",
}

/**
 * If monthly recurrence is selected, option is either by day of week or by day of month
 */
export enum MonthlyRecurrenceOption {
  BY_DAY,
  BY_MONTH_DAY,
}

/**
 * Reason for an indirect appointment.
 */
export enum IndirectReason {
  SCHEDULING_CHANGE = "SCHEDULING_CHANGE",

  CLIENT_CANCELLATION = "CLIENT_CANCELLATION",

  DRIVE_TIME = "DRIVE_TIME",

  RBT_TRAINING = "RBT_TRAINING", //List for module number in notes
  CPR_TRAINING = "CPR_TRAINING",
  HIPAA_TRAINING = "HIPAA_TRAINING",
  OTHER_TRAINING = "OTHER_TRAINING", //List training in notes

  MEETING = "MEETING",

  PAID_TIME_OFF = "PAID_TIME_OFF",
  SICK_TIME = "SICK_TIME",
  VACATION = "VACATION",
  HOLIDAY = "HOLIDAY",
  COMPANY_EVENT = "COMPANY_EVENT",

  MATERIALS_MAKING = "MATERIALS_MAKING",
  MATERIALS_PICKUP = "MATERIALS_PICKUP",

  SHADOW_SESSION = "SHADOW_SESSION",
  BCBA_INTERNSHIP = "BCBA_INTERNSHIP",

  OTHER = "OTHER",
}

export const indirectReasonOrder = Object.values(IndirectReason);

export enum IndirectSummaryBuckets {
  SCHEDULING_CHANGE = "SCHEDULING_CHANGE",
  CLIENT_CANCELLATION = "CLIENT_CANCELLATION",
  DRIVE_TIME = "DRIVE_TIME",
  TRAINING = "TRAINING",
  MEETING = "MEETING",
  OUT_OF_OFFICE = "OUT_OF_OFFICE",
  SESSION_PREP = "SESSION_PREP",
  SHADOW_SESSION = "SHADOW_SESSION",
  SKILL_BUILDING = "SKILL_BUILDING",
  OTHER = "OTHER",
}

export enum PayRateType {
  INDIRECT = "indirect",
  DIRECT = "direct",
}

export const indirectReasonToBucketMap: Record<IndirectReason, IndirectSummaryBuckets> = {
  [IndirectReason.SCHEDULING_CHANGE]: IndirectSummaryBuckets.SCHEDULING_CHANGE,

  [IndirectReason.CLIENT_CANCELLATION]: IndirectSummaryBuckets.CLIENT_CANCELLATION,

  [IndirectReason.DRIVE_TIME]: IndirectSummaryBuckets.DRIVE_TIME,

  [IndirectReason.RBT_TRAINING]: IndirectSummaryBuckets.TRAINING,
  [IndirectReason.CPR_TRAINING]: IndirectSummaryBuckets.TRAINING,
  [IndirectReason.HIPAA_TRAINING]: IndirectSummaryBuckets.TRAINING,
  [IndirectReason.OTHER_TRAINING]: IndirectSummaryBuckets.TRAINING,

  [IndirectReason.MEETING]: IndirectSummaryBuckets.MEETING,

  [IndirectReason.PAID_TIME_OFF]: IndirectSummaryBuckets.OUT_OF_OFFICE,
  [IndirectReason.SICK_TIME]: IndirectSummaryBuckets.OUT_OF_OFFICE,
  [IndirectReason.VACATION]: IndirectSummaryBuckets.OUT_OF_OFFICE,
  [IndirectReason.HOLIDAY]: IndirectSummaryBuckets.OUT_OF_OFFICE,
  [IndirectReason.COMPANY_EVENT]: IndirectSummaryBuckets.OUT_OF_OFFICE,

  [IndirectReason.MATERIALS_MAKING]: IndirectSummaryBuckets.SESSION_PREP,
  [IndirectReason.MATERIALS_PICKUP]: IndirectSummaryBuckets.SESSION_PREP,

  [IndirectReason.SHADOW_SESSION]: IndirectSummaryBuckets.SHADOW_SESSION,
  [IndirectReason.BCBA_INTERNSHIP]: IndirectSummaryBuckets.SKILL_BUILDING,

  [IndirectReason.OTHER]: IndirectSummaryBuckets.OTHER,
};

export const indirectSummaryBucketColors: Record<IndirectSummaryBuckets, string> = {
  [IndirectSummaryBuckets.SCHEDULING_CHANGE]: "#ff4d4f",
  [IndirectSummaryBuckets.CLIENT_CANCELLATION]: "#ffa940",
  [IndirectSummaryBuckets.DRIVE_TIME]: "#ffec3d",
  [IndirectSummaryBuckets.TRAINING]: "#bae637",
  [IndirectSummaryBuckets.MEETING]: "#36cfc9",
  [IndirectSummaryBuckets.OUT_OF_OFFICE]: "#4096ff",
  [IndirectSummaryBuckets.SESSION_PREP]: "#9254de",
  [IndirectSummaryBuckets.SHADOW_SESSION]: "#73d13d",
  [IndirectSummaryBuckets.SKILL_BUILDING]: "#f759ab",
  [IndirectSummaryBuckets.OTHER]: "#ff7a45",
};

/**
 * Reason for an appointment cancellation.
 */
export enum CancellationReason {
  THERAPIST_SICK = "THERAPIST_SICK",
  THERAPIST_VACATION = "THERAPIST_VACATION",
  THERAPIST_NO_SHOW = "THERAPIST_NO_SHOW",
  THERAPIST_MEETING = "THERAPIST_MEETING",
  THERAPIST_PERSONAL = "THERAPIST_PERSONAL",
  THERAPIST_OTHER = "THERAPIST_OTHER",

  CLIENT_SICK = "CLIENT_SICK",
  CLIENT_VACATION = "CLIENT_VACATION",
  CLIENT_NO_SHOW = "CLIENT_NO_SHOW",
  CLIENT_OTHER = "CLIENT_OTHER",

  OTHER_HOLIDAY = "OTHER_HOLIDAY",
  OTHER_WEATHER = "OTHER_WEATHER",
  OTHER = "OTHER", // e.g. Holiday
}

/**
 * Reason for an appointment cancellation request rejection.
 */
export enum CancellationRejectionReason {
  MANDATORY_APPOINTMENT = "MANDATORY_APPOINTMENT",
  VACATION_DENIED = "VACATION_DENIED",
  SICKNESS_DENIED = "SICKNESS_DENIED",
  OVERUSE = "OVERUSE",
  INVALID_USE_CASE = "INVALID_USE_CASE",
  OTHER = "OTHER",
}

//TYPES

/**
 * If weekly recurrence is selected, true means the event will repeat on that day of week
 * as denoted by array index. Sunday = 0, Saturday = 6.
 */
export type IWeeklyRecurrenceOption = [
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean
];

// TODO: status state history
export enum AppointmentBillingStatus {
  MC_MANUAL_HOLD = "MC_MANUAL_HOLD", // Appointment is on hold in Mission Control, needs to be manually pushed to Adonis
  MC_BUNDLING_HOLD = "MC_BUNDLING_HOLD", // Appointments with same client, same day haven't been completed.
  MC_VALIDATION_FAILED = "MC_VALIDATION_FAILED", // MC validation failed, requires manual fix + re-push to Adonis
  ADONIS_UPLOAD_FAILED = "ADONIS_UPLOAD_FAILED", // Adonis upload failed, requires manual fix + re-push to Adonis
  ADONIS_REVIEW_PENDING = "ADONIS_REVIEW_PENDING", // Adonis review pending, no action required
  ADONIS_ADJUSTMENTS_REQUIRED = "ADONIS_ADJUSTMENTS_REQUIRED", // Adonis review required (ESCALATED_TO_PROVIDER, ESCALATED_TO_MANAGER), requires manual fix in Adonis
  SUBMITTED_TO_PAYER = "SUBMITTED_TO_PAYER", // Claim has been submitted to payer by Adonis, no action required
  ACKNOWLEDGED_BY_PAYER = "ACKNOWLEDGED_BY_PAYER", // Payer has acknowledged the claim, no action required
  PAYMENT_MISMATCH = "PAYMENT_MISMATCH", // Paid amount does not equal the charge amount - either appeal or or accept difference
  REJECTED = "REJECTED", // Claim has been rejected by payer or clearinghouse before being adjucated, requires manual fix in adonis
  PAYER_DENIED = "PAYER_DENIED", // Payer has denied the claim, requires manual fix in adonis + resubmission
  PAYER_APPEALED = "PAYER_APPEALED", // Claim has been denied and has since been appealed
  PAYMENT_PENDING = "PAYMENT_PENDING", // Claim has been approved, payment is pending
  PAYMENT_RECEIVED_END_STATE = "PAYMENT_RECEIVED_END_STATE", // Payment has been received, no action required
  CLAIM_VOIDED_END_STATE = "CLAIM_VOIDED_END_STATE", // Claim has been voided, no action required
}

export interface IAppointmentBillingStatusChange {
  status: AppointmentBillingStatus;
  timestamp: FieldValue | Timestamp;
  notes: string[];
}

/**
 * Created from an appointment that is either cancelled or ready for final review before billing. Extends {@link IEvent}
 * These are mostly ready to be billed, but may have some errors that need to be fixed.
 * Once finalized, they are converted to {@link IBilledAppointment} objects.
 *
 * @param id The completed appointment's ID
 * @param eventType {@link EventType.COMPLETED}
 * @param clinicId The ID of the clinic that the completed appointment belongs to
 * @param clientId The ID of the {@link IClient} that the completed appointment belongs to
 * @param userIds The IDs of the {@link IUser}s that are attendees of the completed appointment
 * @param renderingUserId The ID of the {@link IUser} that is the user that rendered the services for this appointment
 * @param billingUserId The ID of the {@link IUser} that is the user we will bill under for this appointment
 * @param noteId The ID of the {@link INote} that is associated with this appointment
 * @param isBilled Whether the appointment has been billed or not
 * @param chargeCents The appointment's charge in cents
 * @param units The appointment's units in whole numbers
 * @param billingCode The appointment's {@link BillingCode}
 * @param modifiers The appointment's {@link Modifier} objects
 * @param location The appointment's {@link AppointmentLocation}
 * @param cancellationStatus If the appointment was cancelled, the {@link CancelledCompletedAppointmentStatus}
 * @param cancellationReason If the appointment was cancelled, the {@link CancellationReason}
 * @param cancellationNotes If the appointment was cancelled, notes about the cancellation
 * @param cancellationRejectionReason If the appointment has a cancellation request and the cancellation was rejected, the {@link CancellationRejectionReason}
 * @param cancellationRejectionNotes If the appointment has a cancellation request and the cancellation was rejected, notes about the rejection
 * @param expectedStartMs If the appointment's expected start time is different from the actual start time, the expected start time in milliseconds
 * @param expectedEndMs If the appointment's expected end time is different from the actual end time, the expected end time in milliseconds
 * @param adonisClaimId If the appointment has been sent to Adonis, the ID of the claim
 * @param statusHistory The appointment's {@link EAppointmentBillingStatus} history
 */
export interface ICompletedAppointment extends IEvent {
  // Fields added due to IEvent extension,
  // Will be copied over from IAppointment
  // - .description
  // - .attendees
  eventType: EventType.COMPLETED;
  clientId: string;
  userIds: string[];
  renderingUserId: string; // user of highest status in userIds
  billingUserId: string; // can be whoever
  noteId?: string;

  isBilled: boolean;
  isOnHold: boolean;

  chargeCents: number;
  units: number;
  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  //Cancellation fields
  cancellationStatus?: CancelledCompletedAppointmentStatus;
  cancellationReason?: CancellationReason;
  cancellationNotes?: string;
  cancellationRejectionReason?: CancellationRejectionReason | null;
  cancellationRejectionNotes?: string | null;

  //store expected start/end times if they are different from actual
  expectedStartMs?: number;
  expectedEndMs?: number;

  evvStartMs?: number;
  evvEndMs?: number;

  internalNotes: string;

  statusHistory?: IAppointmentBillingStatusChange[];
  adonisClaimId?: string;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  cancelledAt?: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

/**
 * A {@link ICompletedAppointment} that has been finalized and is ready to be billed.
 * This object serializes all relevant objects that go into a claim to capture an entire
 * snapshot of all objects at the time of billing. This is because we expect objects to change
 * over time, but we want to ensure that our historical billing data does not change.
 *
 * The presence of a IBilledAppointment object in Firestore does not necessarily mean
 * that the appointment has been sent to our Billing Partners to be billed. It is instead
 * indicated by the @param exportedAt field.
 *
 * @param id The billed appointment's ID
 * @param eventType {@link EventType.BILLED}
 * @param clinicId The ID of the clinic that the billed appointment belongs to
 * @param clientId The entire {@link IClient} that the billed appointment belongs to
 * @param userIds An array of the entire {@link IUser}s that are attendees of the billed appointment
 * @param renderingUserId The entire {@link IUser} that is the user that rendered the services for this appointment
 * @param billingUserId The entire {@link IUser} that is the user we will bill under for this appointment
 * @param noteId The entire {@link INote} that is associated with this appointment
 * @param payer The entire priamry {@link IPayer} that is associated with this appointment
 * @param secondaryPayer The entire secondary {@link IPayer} that is associated with this appointment
 * @param chargeCents The billed appointment's charge in cents
 * @param units The billed appointment's units in whole numbers
 * @param billingCode The billed appointment's {@link BillingCode}
 * @param modifiers The billed appointment's {@link Modifier} objects
 * @param location The billed appointment's {@link AppointmentLocation}
 * @param summary The billed appointment's summary
 * @param startMs The billed appointment's start time in milliseconds
 * @param endMs The billed appointment's end time in milliseconds
 * @param expectedStartMs If the appointment's expected start time is different from the actual start time, the expected start time in milliseconds
 * @param expectedEndMs If the appointment's expected end time is different from the actual end time, the expected end time in milliseconds
 * @param exportedAt The time that the billed appointment was exported to our Billing Partners
 */
export interface IBilledAppointment {
  id: string; // Primary key, same as GCal event ID
  eventType: EventType;

  clinicId: string;

  clinic: IClinic;
  client: IClient;
  guardian: IGuardian;
  clientFile: IClientFile;
  authorization: IAuthorization;
  users: IUser[];
  renderingUser: IUser; // user of highest status in userIds
  billingUser: IUser; // user we are billing under
  note: INote | null;

  payer: IPayer;
  secondaryPayer?: IPayer;

  chargeCents: number;
  units: number;
  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  summary: string;

  startMs: number;
  endMs: number;

  // Updated when money is collected from payer (only affects appointments billed through Adonis API)
  collectedCents?: number;

  //store expected start/end times if they are different from actual
  expectedStartMs?: number;
  expectedEndMs?: number;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  exportedAt?: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export const deployMetadataSchema = z.object({
  // Currently, only mission control and orbit have deployment records
  id: z.enum([AppName.MISSION_CONTROL, AppName.ORBIT]),
  latestDeployMs: z.number(),
});

export type IDeployMetadata = z.infer<typeof deployMetadataSchema>;

export const eventSchema = z.object({
  id: z.string(),
  eventType: z.nativeEnum(EventType),

  clinicId: z.string(),
  attendees: z.array(z.object({ email: z.string(), status: z.nativeEnum(AttendeeStatus) })),

  summary: z.string(),
  description: z.string(),

  startMs: z.number(),
  endMs: z.number(),
});
export type IEvent = z.input<typeof eventSchema>;

/**
 * An indirect event for providers to log indirect time. Extends {@link IEvent}
 *
 * "Indirect" means that a provider is not directly with a client, but is still working on behalf of a client.
 * This can include things like writing notes, creating programs, etc. Indirect events are not billable.
 *
 * On the other hand, "Direct" means that a provider is directly with a client.
 * This can include things like therapy sessions, parent training, etc. Direct events are billable.
 *
 * @param id The indirect event's ID
 * @param eventType {@link EventType.INDIRECT}
 * @param clinicId The ID of the clinic that the indirect event belongs to
 * @param attendees The attendees of the indirect event. A map of emails and their {@link AttendeeStatus}
 * @param summary The indirect event's summary
 * @param description The indirect event's description
 * @param startMs The indirect event's start time in milliseconds
 * @param endMs The indirect event's end time in milliseconds
 * @param indirectReason The indirect event's {@link IndirectReason}
 * @param status The indirect event's {@link IndirectStatus}
 * @param rejectionReason If the indirect event was rejected, the {@link IndirectRejectionReason}
 * @param rejectionNotes If the indirect event was rejected, notes about the rejection
 * @param isDirectPayRate Whether the indirect event is a direct pay rate
 * @param rrule The RRULE string for the indirect. This is used to calculate recurrence dates.
 * @param recursUntil The recurrence end date for the indirect event. This should stay in sync with the RRULE until date.
 */
export const indirectSchema = eventSchema.merge(
  z.object({
    indirectReason: z.nativeEnum(IndirectReason),
    status: z.nativeEnum(IndirectStatus),
    rejectionReason: z.nativeEnum(IndirectRejectionReason).nullish(),
    rejectionNotes: z.string().nullish(),
    isDirectPayRate: z.boolean().optional(),
    rrule: z.string().nullish(),
    recursUntil: z.number().optional(),
    parentId: z.string().optional(),
    createdAt: z.instanceof(Timestamp),
    updatedAt: z.instanceof(Timestamp),
    deletedAt: z.instanceof(Timestamp).optional(),
  })
);

const fieldValueSchema = z.custom<FieldValue>((val) => val instanceof FieldValue);

export type IIndirect = z.input<typeof indirectSchema>;

export const indirectToDBSchema = indirectSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .merge(
    z.object({
      createdAt: fieldValueSchema,
      updatedAt: fieldValueSchema,
      deletedAt: fieldValueSchema.optional(),
    })
  );

export type IIndirectToDB = z.input<typeof indirectToDBSchema>;

/**
 * Appointment abstraction. The Google Calendar service serializes
 * calendar_v3.Schema$Event objects into IAppointments
 *
 * @param id The event ID from Google Calendar
 */
export interface IAppointment extends IEvent {
  clientId: string;
  noteId: string | null;
  sessionId: string | null;

  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  rrule?: string;
  meetsLink?: string;

  source?: AppointmentSource;
  expectedRevenue?: IExpectedRevenue;

  evvStartMs?: number;
  evvEndMs?: number;
  isCompleted?: boolean;
}

/**
 * Monthly recurrence options
 */
export interface IMonthlyRecurrenceOption {
  option: MonthlyRecurrenceOption;
  startDate?: Moment;
}

/**
 * Interface to represent a parsed RRule
 */
export interface IParsedRRule {
  option: CalendarRecurrenceOption;
  weeklyOption?: IWeeklyRecurrenceOption;
  monthlyOption?: IMonthlyRecurrenceOption;
  endDate?: Moment;
}

export type ICalendarAppointment =
  | (IAppointment | ICompletedAppointment) & {
      notes?: INote[];
    };

export type ICalendarEvent = ICalendarAppointment | IIndirect;

export type ICalendarEventsByDay = {
  [key in Weekday]: ICalendarEvent[];
};

export interface ICalendarRow extends ICalendarEventsByDay {
  person: IUser | IClient;
}

export interface ICalendarEventExtended {
  event: ICalendarEvent;
  users: IUser[];
  clientDetails?: IClientDetails;
  notes?: INote[];
  date: string; //YYYY-MM-DD
  type: CalendarEventType;
}

export type ICalendarEventExtendedByDay = Map<string, ICalendarEventExtended[]>;
