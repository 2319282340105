import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { appointmentAuditResultSchema } from "../types/general";
const c = initContract();

export const aiContract = c.router({
  generateNarrative: {
    method: "POST",
    path: "/ai/generate-narrative",
    body: z.object({
      sessionInfo: z.string(),
    }),
    responses: {
      200: z.string(),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  auditAppointment: {
    method: "POST",
    path: "/ai/audit-appointment",
    body: z.object({
      appointmentId: z.string(),
    }),
    responses: {
      200: appointmentAuditResultSchema,
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
