// Stronger typing for Object.keys
export function keys<O extends object>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

export function isTruthy<T>(value: T | null | undefined): value is T {
  return Boolean(value);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function hasProperty<T, K extends string>(
  obj: T,
  property: K
): obj is T & Record<K, unknown> {
  return typeof obj === "object" && obj !== null && property in obj;
}

export function arrayIncludes<T, TArray extends readonly T[]>(
  array: TArray,
  element: T
): element is TArray[number] {
  return array.includes(element);
}
