import { z } from "zod";

import { IndirectRejectionReason } from "../types/calendar";

export const approveIndirectEndpointRequestSchema = z.object({
  id: z.string().min(1),
});

export const rejectIndirectEndpointRequestSchema = z.object({
  id: z.string().min(1),
  rejectionReason: z.nativeEnum(IndirectRejectionReason),
  rejectionNotes: z.string(),
});

export type IApproveIndirectEndpointRequest = z.input<typeof approveIndirectEndpointRequestSchema>;

export type IRejectIndirectEndpointRequest = z.input<typeof rejectIndirectEndpointRequestSchema>;

export interface IDeleteIndirectEndpointRequest {
  id: string;
  clientFileId?: string;
}
